export interface Declaration {
  id: number;
  invoice_id: number;
  healthcare_provider_id: number;
  declaration_external_id?: string;
  declaration_external_uuid?: string;
  declaration_pdf_external_id: string;
  end_state_at: string;
  reference: string;
  status: DeclarationStatus;
  response: string;
  pm305json?: DeclarationPm305Json;
  gds802json?: DeclarationGds802Json;
  type: DeclarationType;
  melding?: {
    Code: string;
    Omschrijving: string;
  };
  readonly created_at: Date;
  readonly updated_at: Date;
}

export type DeclarationType = "vecozo.pm304" | "vecozo.gds801";

export const declarationStatus = [
  "OntvangenDoorVecozo",
  "CorrectBevondenDoorVecozo",
  "KlaarVoorVerzendingNaarZorgverzekeraar",
  "SuccesvolOntvangenDoorZorgverzekeraar",
  "AfgekeurdDoorVecozo",
  "AfgehandeldDoorZorgverzekeraar",
] as const;

export type DeclarationStatus = (typeof declarationStatus)[number];

export function statusIsPending(status: Declaration["status"]): boolean {
  return [
    "OntvangenDoorVecozo",
    "CorrectBevondenDoorVecozo",
    "KlaarVoorVerzendingNaarZorgverzekeraar",
    "SuccesvolOntvangenDoorZorgverzekeraar",
  ].includes(status);
}

export type DeclarationPm305Json = {
  sluit: DeclarationPm305JsonEntrySluit;
  voorloop: DeclarationPm305JsonEntryVoorloop;
  prestaties: DeclarationPm305JsonEntryPrestatie[];
  verzekerden: DeclarationPm305JsonEntryVerzekerde[];
};

type DeclarationPm305JsonEntrySluit = {
  kenmerk_record: string;
  totaal_toegekend_bedrag: string;
  indicatie_debet_credit_1: string;
  indicatie_debet_credit_2: string;
  aantal_detailrecords_retour: string;
  aantal_debiteurrecords_retour: string;
  aantal_prestatierecords_retour: string;
  aantal_commentaarrecords_retour: string;
  aantal_verzekerdenrecords_retour: string;
  totaal_ingediend_declaratiebedrag: string;
};

type DeclarationPm305JsonEntryVoorloop = Retourcodes & {
  dagtekening_retourbericht: string;
  referentienr_zorgverzekeraar: string;
};

type Retourcodes = {
  retourcode_1: string;
  retourcode_2: string;
  retourcode_3: string;
};

type DeclarationPm305JsonEntryPrestatie = Retourcodes & {
  indicatie_debet_credit_3: "D" | "C";
  indicatie_debet_credit_4: "D" | "C";
  referentienummer_dit_record: string;
  berekend_bedrag_zorgverzekeraar: string;
  toegekend_bedrag_zorgverzekeraar: string;
  referentienummer_voorgaand_record: string;
};

type DeclarationPm305JsonEntryVerzekerde = Retourcodes & {
  patient_identificatie_nummer: string;
};

type FeedbackElement = {
  type: string;
  retourcode: string;
  returnCodeMeaning?: string;
};

type DeclarationGds802JsonEntryPrestatie = {
  feedback: FeedbackElement[];
  referentienummer: string;
  toegekendBedragInclBtwFinancieel: number;
  toegekendBedragInclBtwNietFinancieel: number;
};

type DeclarationGds802JsonPrice = {
  bedrag: number;
  debetCreditCode: "D" | "C";
};

export type DeclarationGds802Json = {
  Header: {
    feedback: FeedbackElement[];
    ontvanger: string;
    verzender: string;
    verzenddatum: string;
    referentienummer: string;
  };
  Overzicht: {
    TotaalDeclaratiebedragInclBtw: DeclarationGds802JsonPrice;
    TotaalToegekendBedragInclBtwFinancieel: DeclarationGds802JsonPrice;
    totaalToegekendBedragInclBtwNietFinancieel: DeclarationGds802JsonPrice;
  };
  Verzekerden: {
    feedback: FeedbackElement[];
    prestaties: {
      debetPrestatie: DeclarationGds802JsonEntryPrestatie | undefined;
      creditPrestatie: DeclarationGds802JsonEntryPrestatie | undefined;
    }[];
    patient_identificatie_nummer: string;
  }[];
  DeclaratieContext: {
    feedback: FeedbackElement[];
    valutacode: string;
    factuurdatum: string;
    factuurnummer: string;
    informatiesysteemCode: string;
    BtwIdentificatienummer: null;
    informatiesysteemVersie: string;
    declaratienummerVerzekeraar: string;
  };
};
